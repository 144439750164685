(function () {

    document.addEventListener("DOMContentLoaded", () => {
        const specSummaryToggle = document.getElementById('spec-summary-toggle'),
                specificationSummary = document.getElementById('specification-summary');

        specSummaryToggle.addEventListener('click', function () {
            specSummaryToggle.classList.toggle('active');
            specificationSummary.classList.toggle('active');

            if ( specSummaryToggle.classList.contains('active') ) {
                specSummaryToggle.innerText = 'Close Summary';
            } else {
                specSummaryToggle.innerText = 'Specification Summary';
            }
        });

    });
}());